import { Routes } from 'constant';
import { generateUrl } from './generateUrl';

export enum SignUpPages {
  CreateAccount = 'create-account',
  HearAboutUs = 'hear-about-us',
  ManagementSystem = 'management-system',
  PartsSuppliers = 'parts-suppliers',
  PhoneNumber = 'phone-number',
  Role = 'role',
  ShopDetails = 'shop-details',
  ShopType = 'shop-type',
  TireSuppliers = 'tire-suppliers',
  Restricted = 'restricted',
}

export type SignUpQueryParams = Partial<{
  /**
   * The selected SMS provider.
   */
  selectedSms: string;

  /**
   * Automatically generated. Used only with Google sign-up.
   */
  code: string;

  /**
   * A JSON string containing all other query parameters except 'state'.
   */
  state: string;

  /**
   * Used only with shop invitations.
   */
  token: string;

  /**
   * Used only with referral sign
   */
  mbsy: string;

  /**
   * Used only with referral sign-up via Google sign-up.
   */
  referralCode: string;
}>;

export const getSignUpUrl = (
  page: SignUpPages = SignUpPages.CreateAccount,
  search?: Omit<SignUpQueryParams, 'code' | 'state'>
) => generateUrl(Routes.SIGN_UP, { page }, search);
