import { useMemo } from 'react';
import { useSignupQueryParams } from 'hooks/signup';
import { denormalizeReferralCode, useAmbassador } from 'integrations/ambassador';
import { useLaunchDarkly } from 'integrations/launchDarkly';

export const useReferralCode = () => {
  const { mbsy } = useSignupQueryParams();

  const { referrerInfo } = useAmbassador();

  const {
    flags: { ambassadorReferralProgram },
  } = useLaunchDarkly();

  return useMemo(() => {
    if (!ambassadorReferralProgram) {
      return undefined;
    }

    if (referrerInfo?.code) {
      return referrerInfo.code;
    }

    if (mbsy) {
      return denormalizeReferralCode(mbsy);
    }

    return undefined;
  }, [ambassadorReferralProgram, referrerInfo?.code, mbsy]);
};
